export default {
	data: () => ({
		bankDetail: {
			id: 0,
			bank_name: "",
			country: "",
			swift_code: "",
		},
		loading: false,
		backUrl: "/bank-detail"
	}),
	methods: {
		resetForm() {
			this.$refs.observer.reset();
			this.bankDetail = {
				id: 0,
				bank_name: "",
				country: "",
				swift_code: "",
			};
		},
		validateBankDetail() {
			this.bankDetail.id > 0 ? this.update() : this.add();
			this.loading = true;
			setTimeout(() => {
				this.loading = false;
			}, 10000);
		},
		update() {
			let _vm = this;
			// Send a POST request to update the bank details
			this.axios
				.post("/bank-details/" + this.bankDetail.id, {
					...this.bankDetail,
					_method: "PATCH"
				})
				.then(function (response) {
					// Update the bankDetail with the response data
					_vm.bankDetail = response.data.data;
					// Redirect to the specified backUrl using Vue Router
					_vm.$router.push(_vm.backUrl);
				})
				.catch(function () {
					// Handle any errors that occur during the request
					// You can add error handling code here
				});
		},

		add() {
			let _vm = this;
			// Send a POST request to "/bank-details" endpoint with the _vm.bankDetail data
			this.axios
				.post("/bank-details", _vm.bankDetail)
				.then(function () {
					// Redirect to the specified backUrl after successful POST request
					_vm.$router.push(_vm.backUrl);
				})
				.catch(function () {
					// Handle any errors or exceptions that occur during the POST request
				});
		},

		getDetail() {
			let _vm = this;
			// Making a GET request to retrieve bank details
			this.axios
				.get("/bank-details/" + this.$route.params.id)
				.then(function (response) {
					// Assigning the retrieved data to the bankDetail property
					_vm.bankDetail = response.data.data;
				})
				.catch(function () {
					// Error handling if the request fails
				});
		}

	},
	mounted() {
		if (this.$route.params.id > 0) {
			this.getDetail();
		}
	}
};

